exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-link-privat-24-tsx": () => import("./../../../src/pages/link/privat24.tsx" /* webpackChunkName: "component---src-pages-link-privat-24-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */)
}

